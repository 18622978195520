body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: system-ui;
}

.forceHide {
  display: none !important;
}

#pcMap {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

#leftMenu {
  display: none;
  width: 20vw;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  pointer-events: none;
  flex-direction: column;
}

#populatedList {
  z-index: 2;
  overflow: auto;
  background-color: white;
  position: absolute;
  width: calc(20vw + 40px);
  margin: 20px;
  /* padding: 0 20px; */
  max-height: 0vh;
  top: 60px;
  border-radius: 5px;
  transition: 500ms;
  pointer-events: none;
  opacity: 0;
}

.showPList {
  opacity: 1 !important;
  pointer-events: all !important;
  max-height: calc(100% - 100px) !important;
}

#itemViewMore {
  z-index: 2;
  overflow: auto;
  background-color: white;
  position: absolute;
  width: calc(20vw + 40px);
  margin: 20px;
  /* padding: 0 20px; */
  max-height: 0vh;
  top: 60px;
  border-radius: 5px;
  transition: 500ms;
  pointer-events: none;
  opacity: 0;
}

.showViewMore {
  opacity: 1 !important;
  pointer-events: all !important;
  max-height: calc(100% - 100px) !important;
}

.itemInList {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 20px;
  cursor: pointer;
  border-radius: 5px;
}

.itemInList:hover {
  transition: 500ms;
  background-color: #fffbdc !important;
  /* rgba(116, 191, 249, 0.303); */
}

.expand.itemInList {
  background-color: #fffbdcd3;
}
.viewMore {
  max-height: 0vh;
  width: 40%;
  margin: 1vh 0vh;
  transition: 200ms;
  transform: scale(1);
  opacity: 0;
}
.viewMore:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.catalogBrief {
  font-size: 1.5vh;
  color: grey;
  max-height: 0vh;
  overflow: hidden;
  transition: 500ms;
}

.exandedViewMore > div > .catalogBrief {
  max-height: 26vh !important;
}
.expand > div > .catalogBrief {
  max-height: 6vh !important;
}
.exandedViewMore > div > .viewMore {
  opacity: 1 !important;
  max-height: 6vh !important;
}

#entryBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 8vh;
  margin: 1vh;
}

#parkingBtn {
  position: absolute;
  bottom: 0;
  right: 7vh;
  height: 8vh;
  margin: 1vh;
}

.btn {
  cursor: pointer;
  opacity: 0.9;
  transition: 200ms;
}

.btn:hover {
  opacity: 1 !important;
  transform: scale(1.1) !important;
}
.btn:active {
  opacity: 0.7 !important;
  transform: scale(1.05) !important;
}
#searchForm {
  height: 65px;
  pointer-events: all;
}
#filtersDiv {
  display: none;
  align-items: center;
  position: absolute;
  left: calc(20vw + 80px);
  top: 23px;
  width: 76vw;
  pointer-events: all;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  flex-wrap: wrap;
  max-width: 65vw;
  gap: 1rem;
  padding-left: 4rem;
}
#filtersDiv img:first-child {
  position: absolute;
  top: 0;
  left: 0;
}
#filtersDiv::-webkit-scrollbar {
  display: none;
}

.filtersTab {
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 1vh 2vh;
  margin: 0vh 1vh;
  white-space: nowrap;
}

.activeTab {
  background-color: rgb(61, 61, 61);
  color: white;
}

.head {
  background-color: rgb(83, 83, 83);
  font-weight: 600;
  border-bottom: none !important;
  > td {
    color: white !important;
    padding: 0.5rem 0.25rem !important;
  }
}

.subHead {
  text-align: center;
  font-weight: 600;
  border-bottom: none !important;

  > td {
    padding: 1.5rem !important;
  }
}

.title {
  text-align: center;
  font-weight: 600;
  border-bottom: none !important;
  > td {
    font-size: 2rem;
    padding: 0.25rem 0 1rem 0 !important;
  }
}

table > tr {
  border-bottom: 1px solid;
}

table > tr > td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.ft-Wt-700 {
  font-weight: 700;
}

@media screen and (max-width: 1680px) {
  html,
  body {
    font-size: 80%;
  }
}

@media screen and (max-width: 1100px) {
  html,
  body {
    font-size: 14px;
  }
}
